<template>
    <div class="mt-4">
        <el-row>
            <el-col :span="24" class="text-center">
                <div class="view-title">Nueva Campaña</div>
            </el-col>
        </el-row>

        <el-row class="mt-3 px-5 py-4 bg-white border border-gray rounded">
            <el-col>

                <el-row class="mb-3">
                    <el-col :span="1">
                        <i class="el-icon-close icon-gray cursor-pointer" @click="exit"></i>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>

                        <campaign-form ref="campaignForm" v-model="campaign"></campaign-form>

                        <el-row class="mt-3">
                            <el-col class="mt-3 d-flex justify-content-end">

                                <el-button
                                    type="text"
                                    :loading="saveLoading"
                                    :disabled="saveLoading"
                                    @click="saveDraft">Guardar Borrador
                                </el-button>

                                <el-button
                                    type="primary"
                                    :loading="publishLoading"
                                    :disabled="publishLoading"
                                    @click="publish">Publicar
                                </el-button>

                            </el-col>
                        </el-row>

                    </el-col>
                </el-row>

            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {messages, dialogs} from '../../constants/text-constants';
    import CampaignForm from "./components/CampaignForm";
    import {activateCampaign, newCampaign} from "../../api/campaign-api";

    export default {
        name: "CampaignNew",
        components: {
            CampaignForm
        },
        data() {
            return {
                campaign: {},
                saveLoading: false,
                publishLoading: false,
            }
        },
        methods: {
            exit() {
                this.$confirm(dialogs.campaign.cancelWizard.text, {
                    confirmButtonText: dialogs.campaign.cancelWizard.confirm,
                    cancelButtonText: dialogs.campaign.cancelWizard.cancel,
                }).then(() => {
                    this.goBack();
                }).catch(() => {});
            },
            publish() {
                this.$refs.campaignForm.validate().then(isValid => {
                    if (isValid) {
                        this.publishLoading = true;
                        newCampaign(this.campaign)
                            .then((campaign) => {
                                activateCampaign(campaign.uuid).then(() => {
                                    this.$message.success(messages.campaign.create.success);
                                    this.goBack();
                                })
                                    .finally(() => this.publishLoading = false);
                            })
                            .catch(error => {
                                if (error.response.status === 464) {
                                    this.$alert('Ya existe una campaña con el mismo nombre de código', {
                                        confirmButtonText: 'De acuerdo'
                                    }).catch(() => {});
                                } else {
                                    this.$message.error(messages.campaign.create.error);
                                }
                            })
                            .finally(() => {
                                this.publishLoading = false;
                            });
                    }
                });
            },
            saveDraft() {
                this.$refs.campaignForm.validate().then(isValid => {
                    if (isValid) {
                        this.saveLoading = true;
                        newCampaign(this.campaign)
                            .then(() => {
                                this.$message.success(messages.campaign.saveDraft.success);
                                this.goBack();
                            })
                            .catch(error => {
                                if (error.response.status === 464) {
                                    this.$alert('Ya existe una campaña con el mismo nombre de código', {
                                        confirmButtonText: 'De acuerdo'
                                    }).catch(() => {});
                                } else {
                                    this.$message.error(messages.campaign.create.error);
                                }
                            })
                            .finally(() => this.saveLoading = false);
                    }
                });
            },
            goBack() {
                this.$router.push({name: 'campaigns-list'})
            }
        },
    }
</script>

<style scoped>
    .el-icon-close {
        font-size: 18px;
    }
</style>
